import {Component, HostBinding, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-home-estaka',
  templateUrl: './home-estaka.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HomeEstakaComponent {
  // see navigation.component.scss
  @HostBinding('class.content-container') contentContainer = true;
  @HostBinding('class.home-container') homeContainer = true;
}
