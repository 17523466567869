import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  multiTranslateLoader,
  ObDocumentMetaService,
  ObHttpApiInterceptor,
  ObHttpApiInterceptorConfig,
  ObHttpApiInterceptorEvents,
  ObIconModule,
  ObMasterLayoutConfig,
  ObMasterLayoutModule,
  ObNotificationService,
  TRANSLATION_FILES,
} from '@oblique/oblique';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {CoreModule} from '@bit/admindir/src/app/core/core.module';
import {LanguageService} from '@bit/admindir/src/app/core/language.service';
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
import {I18nPaginator} from '@bit/admindir/src/app/core/i18n-paginator';
import {LocaleService} from '@bit/admindir/src/app/core/locale.service';
import {AppRoutingModule} from './app-routing.module';
import {PersonEstakaModule} from './person-estaka/person-estaka.module';
import {HomeEstakaModule} from './home-estaka/home-estaka.module';
import {OrganizationEstakaModule} from './organization-estaka/organization-estaka.module';
import {SearchEstakaModule} from './search-estaka/search-estaka.module';
import {NAVIGATION_API_URL} from '@bit/admindir/src/app/core/navigation/navigation-api-url.token';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ObMasterLayoutModule,
    TranslateModule.forRoot(multiTranslateLoader()),
    ObIconModule.forRoot(),
    HttpClientModule,
    CoreModule,
    PersonEstakaModule,
    HomeEstakaModule,
    OrganizationEstakaModule,
    SearchEstakaModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ObHttpApiInterceptor, multi: true},
    {
      provide: MatPaginatorIntl,
      useFactory: (translateService: TranslateService) => new I18nPaginator(translateService),
      deps: [TranslateService],
    },
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (sessionService: LocaleService) => sessionService.getCurrentLocale(),
    },
    {
      provide: TRANSLATION_FILES,
      useValue: [
        // AdminDir translations must be loaded before local translations, so duplicated tokens can be overwritten
        {prefix: './assets/admindir/i18n/', suffix: '.json'},
        {prefix: './assets/i18n/', suffix: '.json'},
      ],
    },
    {
      provide: NAVIGATION_API_URL,
      useValue: environment.api.navigationUrl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private readonly notificationService: ObNotificationService,
    private readonly httpApiInterceptorConfig: ObHttpApiInterceptorConfig,
    private readonly httpApiInterceptorEvents: ObHttpApiInterceptorEvents,
    private readonly translateService: TranslateService,
    private readonly documentMetaService: ObDocumentMetaService,
    private readonly masterLayoutConfig: ObMasterLayoutConfig,
    private readonly languageService: LanguageService
  ) {
    this.httpApiInterceptorConfig.api.url = '/api';
    this.httpApiInterceptorConfig.timeout = environment.api.timeoutMs;

    this.documentMetaService.titleSuffix = 'i18n.application.title';
    this.documentMetaService.description = this.documentMetaService.titleSuffix;

    this.masterLayoutConfig.locale.defaultLanguage = languageService.getDefaultLanguage();
    this.masterLayoutConfig.layout.hasLayout = false;
    this.masterLayoutConfig.layout.hasMainNavigation = false;

    this.httpApiInterceptorEvents.sessionExpired.subscribe(() => {
      this.translateService.get('i18n.system.sessionExpired').subscribe((text: string) => {
        this.notificationService.warning(text);
      });
    });
  }
}
