import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonEstakaComponent} from './person-estaka.component';
import {CoreModule} from '@bit/admindir/src/app/core/core.module';
import {PersonModule} from '@bit/admindir/src/app/person/person.module';

@NgModule({
  declarations: [PersonEstakaComponent],
  imports: [CommonModule, CoreModule, PersonModule],
})
export class PersonEstakaModule {}
