import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeEstakaComponent} from './home-estaka.component';
import {CoreModule} from '@bit/admindir/src/app/core/core.module';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {HomeModule} from '@bit/admindir/src/app/home/home.module';

@NgModule({
  declarations: [HomeEstakaComponent],
  imports: [CommonModule, CoreModule, MatCardModule, TranslateModule, MatButtonModule, HomeModule],
})
export class HomeEstakaModule {}
