import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrganizationEstakaComponent} from './organization-estaka.component';
import {CoreModule} from '@bit/admindir/src/app/core/core.module';
import {OrganizationModule} from '@bit/admindir/src/app/organization/organization.module';
import {ORGANIZATION_PERSON_DISPLAY_COLUMNS} from '@bit/admindir/src/app/core/abstract-table/display-columns.token';
import {PersonTableColumns} from '@bit/admindir/src/app/organization/person-table/person-table-columns.enum';

@NgModule({
  declarations: [OrganizationEstakaComponent],
  imports: [CommonModule, CoreModule, OrganizationModule],
  providers: [
    {
      provide: ORGANIZATION_PERSON_DISPLAY_COLUMNS,
      useValue: [PersonTableColumns.Function, PersonTableColumns.Name, PersonTableColumns.Phone],
    },
  ],
})
export class OrganizationEstakaModule {}
