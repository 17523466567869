import {Component, HostBinding} from '@angular/core';
import {SizeDetectorService} from '@bit/admindir/src/app/core/size-detector/size-detector.service';

@Component({
  selector: 'app-search-estaka',
  templateUrl: './search-estaka.component.html',
})
export class SearchEstakaComponent {
  @HostBinding('class.content-container') contentContainer = true;

  constructor(public readonly sizeDetectorService: SizeDetectorService) {}
}
