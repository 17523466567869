import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ObUnknownRouteModule} from '@oblique/oblique';
import {PersonEstakaComponent} from './person-estaka/person-estaka.component';
import {OrganizationEstakaComponent} from './organization-estaka/organization-estaka.component';
import {HomeEstakaComponent} from './home-estaka/home-estaka.component';
import {SearchEstakaComponent} from './search-estaka/search-estaka.component';

const routes: Routes = [
  {path: 'home', component: HomeEstakaComponent, data: {title: 'i18n.application.frontPageTitle'}},
  {path: 'person/:id', component: PersonEstakaComponent},
  {path: 'organization/:id', component: OrganizationEstakaComponent},
  {path: 'search', component: SearchEstakaComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', redirectTo: 'home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top', anchorScrolling: 'enabled'}), ObUnknownRouteModule],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
