<ob-master-layout [navigation]="navigation">
  <ng-container obHeaderTitle>{{ 'i18n.application.title' | translate }}</ng-container>

  <ng-container obHeaderCustomControl *ngIf="isMobile">
    <app-mobile-search></app-mobile-search>
  </ng-container>

  <ng-container obNavigation *ngIf="isMobile">
    <app-navigation-container></app-navigation-container>
  </ng-container>

  <ng-template #obFooterLink>
    <a class="mr-2" href="mailto:{{ 'i18n.footer.contactEmail' | translate }}">{{ 'i18n.footer.contact' | translate }}</a>
    <a href="{{ 'i18n.footer.legalLink' | translate }}" target="_blank" rel="noopener noreferrer">{{ 'i18n.footer.legal' | translate }}</a>
  </ng-template>

  <ng-container obFooterInfo>{{ 'i18n.footer.title' | translate }}</ng-container>
</ob-master-layout>
<app-size-detector></app-size-detector>
