import {Component, HostBinding} from '@angular/core';

@Component({
  selector: 'app-person-estaka',
  templateUrl: './person-estaka.component.html',
})
export class PersonEstakaComponent {
  // see navigation.component.scss
  @HostBinding('class.content-container') contentContainer = true;
}
