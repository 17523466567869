import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ObINavigationLink, ObMasterLayoutService} from '@oblique/oblique';
import {Subscription} from 'rxjs';
import {SizeDetectorService} from '@bit/admindir/src/app/core/size-detector/size-detector.service';
import {ScreenSize} from '@bit/admindir/src/app/core/size-detector/screen-size.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public navigation: ObINavigationLink[] = [];
  public isMobile = false;

  private sizeDetectionSubscription: Subscription;

  constructor(
    private readonly sizeDetectorService: SizeDetectorService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly masterLayoutService: ObMasterLayoutService
  ) {}

  ngOnInit(): void {
    this.sizeDetectionSubscription = this.sizeDetectorService.isMobile$.subscribe((value: boolean) => {
      this.isMobile = value;
      this.masterLayoutService.layout.hasMainNavigation = this.isMobile;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.sizeDetectionSubscription.unsubscribe();
  }
}
