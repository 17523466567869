import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchEstakaComponent} from './search-estaka.component';
import {CoreModule} from '@bit/admindir/src/app/core/core.module';
import {PERSON_DISPLAY_COLUMNS} from '@bit/admindir/src/app/core/abstract-table/display-columns.token';
import {SearchTableColumn} from '@bit/admindir/src/app/core/search/search-table-column.enum';

@NgModule({
  declarations: [SearchEstakaComponent],
  imports: [CommonModule, CoreModule],
  providers: [
    {
      provide: PERSON_DISPLAY_COLUMNS,
      useValue: [
        SearchTableColumn.Surname,
        SearchTableColumn.GivenName,
        SearchTableColumn.Function,
        SearchTableColumn.Department,
        SearchTableColumn.Organization,
        SearchTableColumn.Division,
        SearchTableColumn.Phone,
      ],
    },
  ],
})
export class SearchEstakaModule {}
